<template>
  <VContainer class="px-0">
    <VRow dense>
      <VCol data-test="content-vote-question">
        {{ meta.question }}
      </VCol>
    </VRow>
    <VRow
      v-if="meta.is_multi"
      dense
    >
      <VCol
        class="tt-black--text text--lighten-2 text-right"
        cols="3"
        data-test="content-vote-title-select"
      >
        Выбор
      </VCol>
      <VCol data-test="content-vote-multi-comment">
        {{ meta.multi_comment }}
      </VCol>
    </VRow>
    <VRow
      v-for="(item, index) in meta.answers"
      :key="index"
      dense
    >
      <VCol
        class="tt-black--text text--lighten-2 text-right"
        cols="3"
        :data-test="`content-vote-title-answer${index}`"
      >
        Ответ {{ index + 1 }}
      </VCol>
      <VCol
        data-test="task-type-comment"
        :data-test-label="`content-vote-comment${index}`"
      >
        <div :data-test="`content-vote-title-comment${index}-label`">
          {{ item.label }}
        </div>
        <div
          class="tt-black--text text--lighten-2"
          :data-test="`content-vote-title-comment${index}-text`"
        >
          {{ item.comment }}
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'ContentVote',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    meta() {
      return JSON.parse(this.data.meta);
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
